import React from 'react';
import Home from './Home';
import AllWorks from './Works/AllWorks';
import { Routes, Route } from 'react-router-dom';
import Page from '../components/Page';
import UiUxDesign from './Works/UiUxDesign';
import SaaSDesign from './Works/SaaSDesign';
import MotionGraphics from './Works/MotionGraphics';
import WebDevelopment from './Works/WebDevelopment';

const Routing = () => {
    return (
        <div>
        <Routes>
          <Route path="/" element={<Page><Home /></Page>} />
          <Route path="/all-works" element={<Page><AllWorks /></Page>} />
          <Route path="/ui-ux-design" element={<Page><UiUxDesign /></Page>} />
          <Route path="/saas-design" element={<Page><SaaSDesign /></Page>} />
          <Route path="/motion-graphics" element={<Page><MotionGraphics /></Page>} />
          <Route path="/web-development" element={<Page><WebDevelopment /></Page>} />
        </Routes>
        </div>
    );
};

export default Routing;