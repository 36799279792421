import React from "react";
import { FiArrowRight } from "react-icons/fi";
import { FaInstagram, FaLinkedinIn, FaDribbble, FaBehance } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TbBrandFiverr } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const social = [
  {
    path: "https://www.instagram.com/idealrahi/",
    icon: <FaInstagram />,
  },
  {
    path: "https://www.linkedin.com/in/md-deluwar-rahi-8b6166220/",
    icon: <FaLinkedinIn />,
  },
  {
    path: "https://dribbble.com/idealrahi",
    icon: <FaDribbble />,
  },
  {
    path: "https://behance.net/idealrahi",
    icon: <FaBehance  />,
  },
  {
    path: "https://x.com/idealrahi",
    icon: <FaXTwitter />,
  },
  {
    path: "https://fiverr.com/idealruix",
    icon: <TbBrandFiverr  />,
  },
];

const WebDevelopmentProjects = ({ setShowWorks, setWorkActiveIndex }) => {
  return (
    <div>
      <div className="portfolio-logo bg-dark mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-1 d-none d-lg-block position-relative overflow-hidden">
              <div className="work-menu">
                <div className="wrapper">
                  <div className="wrap">
                    <NavLink
                      to={"/"}
                    >
                      Home.
                    </NavLink>
                    <NavLink
                      className="active"
                      to={"/all-works"}
                    >
                      Work.
                    </NavLink>
                    <HashLink to={"/#services"}>
                      Service.
                    </HashLink>
                    <HashLink to={"/#about"}>About. </HashLink>
                    <HashLink to={"#contact"}>Contact. </HashLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-10">
              <div className="portfolio-main">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="logo-hero">
                        <h1>
                          Website <br /> Development
                        </h1>
                        <p>
                          From front-end to back-end, I have experience in
                          end-to-end website development solutions tailored to
                          your specific requirements. Also strong experience
                          with WordPress.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1 d-none d-lg-flex align-items-center justify-content-end position-relative">
              <div className="social position-absolute">
                {social.map((x, index) => (
                  <a key={index} href={x.path}>
                    {x.icon}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="web-development-works">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="work work-thin d-flex flex-column gap-4">
                <a href="https://ivhub.com/">
                  <img
                    className="img-fluid"
                    src="/img/web-development/ivhub.webp"
                    alt="work"
                  />
                </a>
                <a role="button" className="work-category">
                  Website Development / ReactJS
                </a>
                <a href="https://ivhub.com/" className="work-title">
                  IVHUB - IV Welless Louge Clinic
                </a>
                <a
                  href="https://ivhub.com/"
                  className="btn web-development-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>View Live Website</span>
                  <FiArrowRight />
                </a>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div className="work d-flex flex-column gap-4">
                <a href="https://zillyplanet.com/">
                  <img
                    className="img-fluid"
                    src="/img/web-development/ZillyPlannet Website Development.webp"
                    alt="work"
                  />
                </a>
                <a role="button" className="work-category">
                  Website Development / WordPress
                </a>
                <a href="https://zillyplanet.com/" className="work-title">
                  ZillyPlannet - Online and entertainment
                </a>
                <a
                  href="https://zillyplanet.com/"
                  className="btn web-development-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>View Live Website</span>
                  <FiArrowRight />
                </a>
              </div>
            </div>
            <div className="col-lg-6 mt-5">
              <div className="work d-flex flex-column gap-4">
                <a href="https://irecleaners.com/">
                  <img
                    className="img-fluid"
                    src="/img/web-development/IREcleaners.webp"
                    alt="work"
                  />
                </a>
                <a role="button" className="work-category">
                  Website Development / WordPress
                </a>
                <a href="https://irecleaners.com/" className="work-title">
                  IREcleaners - Cleaning Services
                </a>
                <a
                  href="https://irecleaners.com/"
                  className="btn web-development-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>View Live Website</span>
                  <FiArrowRight />
                </a>
              </div>
            </div>
            <div className="col-lg-6 mt-5">
              <div className="work work-thin-2 d-flex flex-column gap-4">
                <a href="https://amer247.com/">
                  <img
                    className="img-fluid"
                    src="/img/web-development/Amer247.webp"
                    alt="work"
                  />
                </a>
                <a role="button" className="work-category">
                  Website Development / NextJS
                </a>
                <a href="https://amer247.com/" className="work-title">
                  Amer247 - Immigration Solutions
                </a>
                <a
                  href="https://amer247.com/"
                  className="btn web-development-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>View Live Website</span>
                  <FiArrowRight />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDevelopmentProjects;
