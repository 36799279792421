import React from 'react';
import {BsArrowRightShort} from 'react-icons/bs'

const capabilityList = [
    "UX/UI Design",
    "SaaS Design",
    "Front-End Devs",
    "WordPress",
    "Motion Grphics",
   
]

const Capabilities = () => {
    return (
        <div className="capabilities" id="services">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="left">
                        <h1>Capabilities.</h1>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-lg-8 mt-5">
                    <div className="left">
                        <p>Create products that connect companies and brands with customers using all my expertise.
                            Animation, websites, desktop apps, mobile apps & are all things I can design!</p>
                    </div>
                </div>
                <div className="col-lg-4 mt-5">
                    <div className="right">
                        <ul>
                            {capabilityList.map((x, index) => (
                                <li key={index}>
                                <BsArrowRightShort/>
                                <span>{x}</span>
                            </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Capabilities;