import React from 'react';
import WorkPage from '../../../components/WorkPage';
import UiUxDesignProjects from './UiUxDesignProjects';

const UiUxDesign = () => {
    return (
        <>
           <WorkPage>
            <UiUxDesignProjects />
           </WorkPage>
        </>
    );
};

export default UiUxDesign;