import React from 'react';
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/+8801942704999?text=Hello!"
      className="whatsapp_float"
      target="_blank"
      rel="noreferrer"
    >
    <FaWhatsapp />
    </a>
  );
};

export default WhatsAppButton;
