import React, { useState } from "react";
import About from "../../components/About";
import Capabilities from "../../components/Capabilities";
import FeaturedWorks from "../../components/FeaturedWorks";
import Hero from "../../components/Hero";
import LeftSideNav from "../../components/LeftSideNav";
import RightSideSocial from "../../components/RightSideSocial";
import Elegro from "../../components/Elegro";
import Testimonial from "../../components/Testimonial";

const Home = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [showWorks, setShowWorks] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      {!isVisible ? (
        <>
          <LeftSideNav
            setShowWorks={setShowWorks}
            setIsVisible={setIsVisible}
          />
          <RightSideSocial />
        </>
      ) : null}
      <Hero />
      <FeaturedWorks
        setShowWorks={setShowWorks}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        setActiveIndex={setActiveIndex}
      />
      <Capabilities />
      <About />
      <Elegro />
      <Testimonial />
    </>
  );
};

export default Home;
