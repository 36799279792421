import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const clients = [
  {
    id: 1,
    name: "Matt",
    handle: "sellermargins.io",
    logo: "/img/testimonial/upwork.png",
    review:
      "Rahi truly exceeded my expectations with his creative dashboard design that perfectly aligned with our brand! 🙌  Working with him was a breeze thanks to his cooperative attitude, timely delivery, and proactive communication. Highly recommend!",
    projectName: "SellerMargin",
    category: "SaaS Design",
    videoUrl: "/video/Test.mp4",
    color: "#FFE600",
  },
  {
    id: 2,
    name: "Marketingtea",
    handle: "Droshipping China Pro",
    logo: "/img/testimonial/fiverr.png",
    review: "Rahi's work is very great, design soon and patient, he'll change according to our requirments and do a good job. It's nice to work with him. Good job!",
    projectName: "Dropshipping China Pro",
    category: "Product Design",
    videoUrl: "/video/Test.mp4",
    color: "#FFE600",
  },
  {
    id: 3,
    name: "Selina Kirk",
    handle: "RentAbout",
    logo: "img/testimonial/fiverr.png",
    review: "I am very pleased with the work that Rahi did on my project. He was able to accurately capture my vision and create designs that were both creative and effective. He was also very responsive to my feedback quickly and efficiently. I would highly recommend Rahi to anyone looking for a talented and experienced freelance designer. He is professional, creative, and efficient, and they deliver high-quality work on time and within budget.",
    projectName: "E-commerce Platform",
    category: "Product Design",
    videoUrl: "/video/Test.mp4",
    color: "#FFE600",
  },
  {
    id: 4,
    name: "Sie Haben",
    handle: "Founder of CrabeCrub",
    logo: "/img/testimonial/fiverr.png",
    review:
      "Really beautiful consistent designs. The seller makes a lot of simple but great UI/UX change suggestions that have made my app more usable and resulted in increased usability and user satisfaction. He is also super friendly and communicates well. I look forward to continuing to work with him. I love the results. He followed and understood our CI and delivered fresh, new elements matching our ideas. The result has been awesome and communication is perfect: fast, friendly and always having some ideas.",
    projectName: "CrazeScrub",
    category: "Mobile App Design",
    videoUrl: "/video/Test.mp4",
    color: "#FFE600",
  },
  {
    id: 5,
    name: "Luisleyvag",
    handle: "CEO Founder",
    logo: "img/testimonial/upwork.png",
    review: "I had an outstanding experience working with Rahi. From the outset, their professional approach and ability to capture the essence of what we needed was truly impressive. The quality of the design delivered exceeded my expectations, showcasing not just creativity but also a deep understanding of our client needs. Communication with Rahi was smooth and constructive, which made the process much easier. I would definitely recommend Rahi to anyone looking for high-quality design and a personalized approach. Thank you for an exceptional job!",
    projectName: "-",
    category: "Mobile App Design",
    videoUrl: "/video/Test.mp4",
    color: "#FFE600",
  },
];

const Testimonial = () => {
  const [activeClient, setActiveClient] = useState(clients[0]);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClientClick = (client) => {
    setActiveClient(client);
    setIsPlaying(false);
  };

  const handleVideoClick = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="client-reviews">
      <div className="container">
        <div className="client-reviews__header">
          <h1>What clients say about Me</h1>
          <p>✭✭✭✭✭</p>
        </div>

        <div className="client-reviews__content">
          <div className="client-reviews__left">
            <img
              src={activeClient.logo}
              alt={`${activeClient.name} logo`}
              className="client-reviews__logo"
            />

            <div className="client-reviews__testimonial">
              <blockquote>"{activeClient.review}"</blockquote>

              <div className="client-reviews__project-info">
                <div className="project-name">{activeClient.projectName}</div>
                <div className="project-line"></div>
                <div className="project-category">{activeClient.category}</div>
              </div>

              <button className="case-study-btn">Case Study</button>
            </div>
          </div>

          <div className="client-reviews__right">
            <div
              className="video-container"
              style={{ backgroundColor: activeClient.color }}
              onClick={handleVideoClick}
            >
              {!isPlaying && (
                <button className="play-btn" aria-label="Play video">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="#414141"
                  >
                    <polygon points="5 3 19 12 5 21 5 3" />
                  </svg>
                </button>
              )}
              {isPlaying && (
                <video src={activeClient.videoUrl} autoPlay controls>
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        </div>

        <div className="client-reviews__clients">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={2000}
            centerMode={false}
            className=""
            customTransition="all 1s linear"
            dotListClass=""
            draggable
            focusOnSelect={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            partialVisible={true} // Corrected prop name
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1200 },
                items: 4.5,
              },
              tablet: {
                breakpoint: { max: 1199, min: 992 },
                items: 3.5,
              },
              ipod: {
                breakpoint: { max: 991, min: 768 },
                items: 2.5,
              },
              mobile: {
                breakpoint: { max: 767, min: 0 },
                items: 1.5,
              },
            }}
            rewind={true}
            rewindWithAnimation={true}
            showDots={false}
            sliderClass=""
            slidesToSlide={0}
            transitionDuration={1000}
          >
            {clients.map((client) => (
              <button
                key={client.id}
                className={`client-button ${
                  activeClient.id === client.id ? "active" : ""
                }`}
                onClick={() => handleClientClick(client)}
              >
                <div className="client-img">
                  <img src="/img/hero/star.svg" alt="client" />
                </div>
                <div className="client-info">
                  <div className="client-name">{client.name}</div>
                  <div className="client-handle">{client.handle}</div>
                </div>
              </button>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
