import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const LeftSideNav = () => {
  return (
    <div className="left-side position-relative">
      <div className="work-menu">
        <div className="wrapper">
          <div className="wrap">
            <NavLink to={"/"} className="active">Home. </NavLink>
            <NavLink to={"/all-works"}>Work.</NavLink>
            <HashLink to="#services">Services. </HashLink>
            <HashLink to="#about">About. </HashLink>
            <HashLink to="#contact">Contact. </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSideNav;
