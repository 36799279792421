import React from "react";
import LetsTalk from "./letsTalk";
import Footer from "./Footer";

const Page = ({children}) => {
  return (
    <>
      {children}
      <LetsTalk />
      <Footer />
    </>
  );
};

export default Page;
