import React from "react";
import { FiArrowRight } from "react-icons/fi";

const Elegro = () => {
  return (
    <div className="elegro py-5">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h1 className="elegro-title mt-6">
            I am creating beautiful projects with some common types of UX and UI tools here.
            </h1>
            <p className="elegro-para mt-6">
            I have good experience in creating user workflows, user-friendly interfaces and I am committed to excellence and adherence to web and mobile design standards and best practices and I can transfer my knowledge and learn new tools fast.
            </p>
            <a href="https://calendly.com/idealrahi/30min?back=1" target="_blank" rel="noreferrer" className="btn elegro-btn mt-4">
              <span>Start A Project. </span>
              <FiArrowRight />
            </a>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0">
            <div className="d-flex justify-content-center">
            <img className="img-fluid" src="/img/elegro/elegro.webp" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Elegro;
