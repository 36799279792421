import React from 'react';
import WorkPage from '../../../components/WorkPage';
import AllWorksProject from './AllWorksProjects';

const AllWorks = () => {
    return (
        <>
            <WorkPage>
                <AllWorksProject />
            </WorkPage>
        </>
    );
};

export default AllWorks;