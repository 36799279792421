import React from 'react';

const LetsTalk = () => {
    return (
        <div  className="lets-talk" id="contact">
        <div  className="container">
            <div  className="row">
                <div  className="col-lg-5">
                    <div  className="left">
                        <h1>Let's <span>talk.</span></h1>
                        <h2>Are you interested to working together?</h2>
                        <div  className="info">
                            <img src=" /img/hero/whatsapp.png" alt="whatsapp"/>
                            <div>
                                <a href="https://wa.me/+8801942704999" target='_blank'>
                                    <p>WhatsApp </p>
                                </a>
                            </div>
                        </div>
                        <div  className="info mb-0">
                            <img src=" /img/hero/envelope.png" alt="envelope"/>
                            <a href="mailto:hey@idealrahi.com">
                                <p>hey@idealrahi.com</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-1"></div>
                <div  className="col-lg-5 mt-5 mt-lg-0">
                    <form  className="form" id="contact_form_submit">
                        <div  className="input-group">
                            <label htmlFor="name">Full name</label>
                            <input id="name"  className="form-control" type="text" placeholder="Type your name"/>
                        </div>
                        <div  className="input-group">
                            <label htmlFor="email">Email</label>
                            <input id="email"  className="form-control" type="text" placeholder="Your mail address"/>
                        </div>
                        <div  className="input-group">
                            <label htmlFor="subject">Subject</label>
                            <input id="subject"  className="form-control" type="text" placeholder="Subject"/>
                        </div>
                        <div  className="input-group">
                            <label htmlFor="massege">Message</label>
                            <textarea name="message" id="message" rows="10" placeholder="Type your message"></textarea>
                        </div>
                        <button type="submit">Send inquiry</button>
                    </form>
                </div>
                <div  className="col-lg-1">
                </div>
            </div>
        </div>
        <p  className="right-txt d-none d-lg-block">LET'S TALK</p>
    </div>
    );
};

export default LetsTalk;