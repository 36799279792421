import React from "react";
import { FaInstagram, FaLinkedinIn, FaDribbble } from "react-icons/fa";
import { BsArrowDown } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const socialFooter = [
  {
    title: "Instagram",
    text: "Life experience explore",
    icon: <FaInstagram />,
    path: "https://www.instagram.com/idealrahi/",
  },
  {
    title: "Linkedin",
    text: "Professions and thinkings",
    icon: <FaLinkedinIn />,
    path: "https://www.linkedin.com/in/md-deluwar-rahi-8b6166220/",
  },
  {
    title: "Dribbble",
    text: "Work Story",
    icon: <FaDribbble />,
    path: "https://dribbble.com/idealrahi",
  },
];

const Footer = ({ setShowWorks }) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <h1 className="name">Md Deluwar Rahi</h1>
            <h4 className="occ">Product Designer</h4>
          </div>
          <div className="col-lg-4 d-flex justify-content-lg-end mt-5 mt-lg-0">
            <a
              href=" /cv/Deluwar-Rahi-Resume.pdf"
              className="btn download-btn"
              download
            >
              <span>Download CV</span>
              <BsArrowDown />
            </a>
          </div>
        </div>
        <div className="row align-items-center mt-sm-5">
          <div className="col-lg-3 col-sm-6 mt-4 mt-sm-5">
            <div className="footer-menu d-flex flex-sm-column ">
              <button>
              <NavLink to="/all-works">Works.</NavLink>
              </button>
              <button>
                <HashLink to="/#services">Service.</HashLink>
              </button>
              <button>
                <HashLink to="/#about">About.</HashLink>
              </button>
            </div>
          </div>
          {socialFooter.map((x, index) => (
            <div key={index} className="col-lg-3 col-sm-6 mt-4 mt-sm-5">
              <a href={x.path} target="_blank" rel="noreferrer" className="single-social">
                <div className="icon">{x.icon}</div>
                <h1>{x.title}</h1>
                <p>{x.text}</p>
              </a>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-12 mt-5">
            <div className="footer-bottom pt-3">
              <p>
                2016-<span>{new Date().getFullYear()}</span> &copy;
                idealrahi.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
