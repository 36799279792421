import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

const sources = []

const Image = ({
  imgSrc1,
  imgSrc2,
  imgSrc3,
  imgSrc4,
  imgSrc5,
  imgSrc6,
}) => {
  const [toggler, setToggler] = useState(false);

const handleImageSrc = () => {
  if(imgSrc1){
    sources.splice(0, sources.length)
    sources.push(imgSrc1)
  }
  if(imgSrc2){
    sources.push(...sources, imgSrc2)
    sources.splice(0, 1)
  }
  if(imgSrc3){
    sources.push(...sources, imgSrc3)
    sources.splice(0, 2)
  }
  if(imgSrc4){
    sources.push(...sources, imgSrc4)
    sources.splice(0, 3)
  }
  if(imgSrc5){
    sources.push(...sources, imgSrc5)
    sources.splice(0, 4)
  }
  if(imgSrc6){
    sources.push(...sources, imgSrc6)
    sources.splice(0, 5)
  }
}


  return (
    <div>
      <>
        <div onClick={() => {
          setToggler(!toggler)
          handleImageSrc()
        }}>
          <img src={imgSrc1} alt="poster" />
        </div>
        <FsLightbox
          toggler={toggler}
          sources={sources}
        />
      </>
    </div>
  );
};

export default Image;
