import React from 'react';
import WorkPage from '../../../components/WorkPage';
import MotionGraphicsProjects from './MotionGraphicsProjects';

const MotionGraphics = () => {
    return (
        <>
        <WorkPage>
            <MotionGraphicsProjects />
        </WorkPage>   
        </>
    );
};

export default MotionGraphics;