import React from 'react';

const About = () => {
    return (
        <div className="about" id="about">
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    <div className="left">
                        <h1>About Rahi <span>studio.</span></h1>
                        <div className="d-flex w-100">
                            <img className="img-fluid me-4 me-sm-5" src=" /img/hero/about-1.png" alt="about" />
                            <img src=" /img/hero/about-2.png" alt="about" />
                        </div>
                        <div className="interest d-none d-lg-block">
                            <h4>Interests.</h4>
                            <div className="d-flex align-items-center">
                                <span>Travel</span>
                                <span>|</span>
                                <span>Music</span>
                                <span>|</span>
                                <span>Team Playing</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-4 mt-5 mt-lg-0">
                    <div className="right">
                        <p>I am a highly talented, driven and flexible Full-Time UI UX And Motion Graphic Designer with
                            a proven record of delivering creative and innovative design solutions. <br/><br/>Strong
                            experience with Website Development (Front-end), WordPress, Webflow, and Shopify!</p>
                        <div className="about-skills d-flex flex-wrap mt-lg-5">
                            <div className="single-skill mt-5">
                                <h3><span className="counter1">795+</span></h3>
                                <p>UI/UX Projects done</p>
                            </div>
                            <div className="single-skill mt-5">
                                <h3><span className="counter2">69</span>%</h3>
                                <p>World Domination</p>
                            </div>
                            <div className="single-skill mt-5">
                                <h3><span className="counter3">500+</span></h3>
                                <p>Unique Clients</p>
                            </div>
                            <div className="single-skill mt-5">
                                <h3><span className="counter4">07+</span></h3>
                                <p>Years of experience</p>
                            </div>
                        </div>
                        <div className="interest d-lg-none">
                            <h4>Interests.</h4>
                            <div className="d-flex align-items-center">
                                <span>Travel</span>
                                <span>|</span>
                                <span>Music</span>
                                <span>|</span>
                                <span>Team Playing</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default About;